<template>
  <div class="NavTop">
    <div class="navBg" ref="navBg" :style="{ top: topVal }">
      <div class="nav w">
        <ul>
          <li :class="{ active: activeRoute === '/' }">
            <router-link to="/" @click.native="handleRouteClick('/')"
              >网站首页</router-link
            >
          </li>
          <li @click="goToTargetComponent" ref="kctx">课程体系</li>
          <li @click="mxkc" ref="mxkc">明星课程</li>
          <li @click="msyc" ref="msyc">名师荟萃</li>
          <li @click="jxsk" ref="jxsk">教学实况</li>
          <li @click="zzsq" ref="zzsq">资质授权</li>
          <li @click="hzhb" ref="hzhb">合作伙伴</li>
          <li @click="mtxw" ref="mtxw">媒体新闻</li>
          <li :class="{ active: activeRoute === '/Expert' }">
            <router-link
              to="/Expert"
              @click.native="handleRouteClick('/Expert')"
              >专家访谈</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavTop",
  data() {
    return {
      topVal: "140px",
      activeRoute: "/",
      homeNavItem: null, // 存储网站首页的元素
      kctxNavItem: null, // 存储课程体系的元素
      mxkcNavItem: null, // 存储明星课程的元素
      msycNavItem: null, // 存储名师荟萃的元素
      jxskNavItem: null,
      zzsqNavItem: null,
      hzhbNavItem: null,
      mtxwNavItem: null,
    };
  },
  methods: {
    checkURLContains(target) {
      return window.location.href.includes(target);
    },
    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 50) {
        this.topVal = "0px";
      } else {
        this.topVal = "140px";
      }
    },
    handleRouteClick(route) {
      this.activeRoute = route;
      if (this.homeNavItem) {
        this.homeNavItem.classList.add("active"); // 添加网站首页的类
      }
      if (this.kctxNavItem) {
        this.kctxNavItem.classList.remove("active"); // 移除课程体系的类
      }
      if (this.mxkcNavItem) {
        this.mxkcNavItem.classList.remove("active"); // 移除明星课程的类
      }
      if (this.msycNavItem) {
        this.msycNavItem.classList.remove("active"); // 移除名师荟萃的类
      }
    },
    updateActiveRoute() {
      this.activeRoute = this.$route.path;
    },
    goToTargetComponent() {
      if (this.checkURLContains("Expert") || this.checkURLContains("piano") || this.checkURLContains("Dance")) {
        this.$router.push({ path: "/", hash: "kctx" });
      } else {
        const topOffset = 1250;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      }

      if (this.homeNavItem) {
        this.homeNavItem.classList.remove("active"); // 移除网站首页的类
      }
      if (this.zzsqNavItem) {
        this.zzsqNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.mxkcNavItem) {
        this.mxkcNavItem.classList.remove("active"); // 移除明星课程的类
      }
      if (this.msycNavItem) {
        this.msycNavItem.classList.remove("active"); // 移除名师荟萃的类
      }
      if (this.kctxNavItem) {
        this.kctxNavItem.classList.add("active"); // 添加课程体系的类
      }
      if (this.jxskNavItem) {
        this.jxskNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.hzhbNavItem) {
        this.hzhbNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.mtxwNavItem) {
        this.mtxwNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
    },
    mxkc() {
      if (this.checkURLContains("Expert") || this.checkURLContains("piano") || this.checkURLContains("Dance")) {
        this.$router.push({ path: "/", hash: "mxkc" });
      } else {
        const topOffset = 2400;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      }

      if (this.homeNavItem) {
        this.homeNavItem.classList.remove("active"); // 移除网站首页的类
      }
      if (this.kctxNavItem) {
        this.kctxNavItem.classList.remove("active"); // 移除课程体系的类
      }
      if (this.msycNavItem) {
        this.msycNavItem.classList.remove("active"); // 移除名师荟萃的类
      }
      if (this.zzsqNavItem) {
        this.zzsqNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.mxkcNavItem) {
        this.mxkcNavItem.classList.add("active"); // 添加明星课程的类
      }
      if (this.jxskNavItem) {
        this.jxskNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.hzhbNavItem) {
        this.hzhbNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.mtxwNavItem) {
        this.mtxwNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
    },
    msyc() {
      if (this.checkURLContains("Expert") || this.checkURLContains("piano") || this.checkURLContains("Dance")) {
        this.$router.push({ path: "/", hash: "msyc" });
      } else {
        const topOffset = 3320;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      }

      if (this.homeNavItem) {
        this.homeNavItem.classList.remove("active"); // 移除网站首页的类
      }
      if (this.kctxNavItem) {
        this.kctxNavItem.classList.remove("active"); // 移除课程体系的类
      }
      if (this.mxkcNavItem) {
        this.mxkcNavItem.classList.remove("active"); // 移除明星课程的类
      }
      if (this.zzsqNavItem) {
        this.zzsqNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.jxskNavItem) {
        this.jxskNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.msycNavItem) {
        this.msycNavItem.classList.add("active"); // 添加名师荟萃的类
      }
      if (this.hzhbNavItem) {
        this.hzhbNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.mtxwNavItem) {
        this.mtxwNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
    },
    jxsk() {
      if (this.checkURLContains("Expert") || this.checkURLContains("piano") || this.checkURLContains("Dance")) {
        this.$router.push({ path: "/", hash: "jxsk" });
      } else {
        const topOffset = 4000;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      }

      if (this.homeNavItem) {
        this.homeNavItem.classList.remove("active"); // 移除网站首页的类
      }
      if (this.kctxNavItem) {
        this.kctxNavItem.classList.remove("active"); // 移除课程体系的类
      }
      if (this.mxkcNavItem) {
        this.mxkcNavItem.classList.remove("active"); // 移除明星课程的类
      }
      if (this.msycNavItem) {
        this.msycNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.zzsqNavItem) {
        this.zzsqNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.jxskNavItem) {
        this.jxskNavItem.classList.add("active"); // 添加名师荟萃的类
      }
      if (this.hzhbNavItem) {
        this.hzhbNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.mtxwNavItem) {
        this.mtxwNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
    },
    zzsq() {
      if (this.checkURLContains("Expert") || this.checkURLContains("piano") || this.checkURLContains("Dance")) {
        this.$router.push({ path: "/", hash: "zzsq" });
      } else {
        const topOffset = 4900;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      }

      if (this.homeNavItem) {
        this.homeNavItem.classList.remove("active"); // 移除网站首页的类
      }
      if (this.kctxNavItem) {
        this.kctxNavItem.classList.remove("active"); // 移除课程体系的类
      }
      if (this.mxkcNavItem) {
        this.mxkcNavItem.classList.remove("active"); // 移除明星课程的类
      }
      if (this.msycNavItem) {
        this.msycNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.jxskNavItem) {
        this.jxskNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.zzsqNavItem) {
        this.zzsqNavItem.classList.add("active"); // 添加名师荟萃的类
      }
      if (this.hzhbNavItem) {
        this.hzhbNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.mtxwNavItem) {
        this.mtxwNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
    },
    hzhb() {
      if (this.checkURLContains("Expert") || this.checkURLContains("piano") || this.checkURLContains("Dance")) {
        this.$router.push({ path: "/", hash: "hzhb" });
      } else {
        const topOffset = 5800;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      }

      if (this.homeNavItem) {
        this.homeNavItem.classList.remove("active"); // 移除网站首页的类
      }
      if (this.kctxNavItem) {
        this.kctxNavItem.classList.remove("active"); // 移除课程体系的类
      }
      if (this.mxkcNavItem) {
        this.mxkcNavItem.classList.remove("active"); // 移除明星课程的类
      }
      if (this.msycNavItem) {
        this.msycNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.jxskNavItem) {
        this.jxskNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.zzsqNavItem) {
        this.zzsqNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.mtxwNavItem) {
        this.mtxwNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.hzhbNavItem) {
        this.hzhbNavItem.classList.add("active"); // 添加名师荟萃的类
      }
    },
    mtxw() {
      if (this.checkURLContains("Expert") || this.checkURLContains("piano") || this.checkURLContains("Dance")) {
        this.$router.push({ path: "/", hash: "mtxw" });
      } else {
        const topOffset = 6700;
        window.scrollTo({ top: topOffset, behavior: "smooth" });
      }

      if (this.homeNavItem) {
        this.homeNavItem.classList.remove("active"); // 移除网站首页的类
      }
      if (this.kctxNavItem) {
        this.kctxNavItem.classList.remove("active"); // 移除课程体系的类
      }
      if (this.mxkcNavItem) {
        this.mxkcNavItem.classList.remove("active"); // 移除明星课程的类
      }
      if (this.msycNavItem) {
        this.msycNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.jxskNavItem) {
        this.jxskNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.zzsqNavItem) {
        this.zzsqNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
      if (this.mtxwNavItem) {
        this.mtxwNavItem.classList.add("active"); // 添加名师荟萃的类
      }
      if (this.hzhbNavItem) {
        this.hzhbNavItem.classList.remove("active"); // 添加名师荟萃的类
      }
    },
  },
  mounted() {
    this.updateActiveRoute(); // 获取当前路由并更新 activeRoute 的值
    window.addEventListener("scroll", this.handleScroll);

    if (this.activeRoute === "/") {
      // 判断当前路由是否为首页，如果是则立即执行 handleScroll() 方法
      this.handleScroll();
    }
    this.homeNavItem = this.$refs.navBg.querySelector(".nav ul li:first-child"); // 获取网站首页的元素
    this.kctxNavItem = this.$refs.kctx; // 获取课程体系的元素
    this.mxkcNavItem = this.$refs.mxkc; // 获取明星课程的元素
    this.msycNavItem = this.$refs.msyc; // 获取名师荟萃的元素
    this.jxskNavItem = this.$refs.jxsk; // 获取名师荟萃的元素
    this.zzsqNavItem = this.$refs.zzsq; // 获取名师荟萃的元素
    this.hzhbNavItem = this.$refs.hzhb; // 获取名师荟萃的元素
    this.mtxwNavItem = this.$refs.mtxw; // 获取名师荟萃的元素

    const fragmentIdentifier = window.location.hash; // 获取片段标识符部分
    switch (fragmentIdentifier) {
      case "#kctx":
        this.goToTargetComponent();
        break;
      case "#mxkc":
        this.mxkc();
        break;
      case "#msyc":
        this.msyc();
        break;
      case "#jxsk":
        this.jxsk();
        break;
      case "#zzsq":
        this.zzsq();
        break;
      case "#zzsq":
        this.zzsq();
        break;
      case "#hzhb":
        this.hzhb();
        break;
      case "#mtxw":
        this.mtxw();
        break;
    }
  },
  watch: {
    $route() {
      this.updateActiveRoute(); // 监听路由变化并更新 activeRoute 的值
    },
    activeRoute() {
      this.$nextTick(() => {
        this.handleScroll();
      });
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (from.path === "/piano" && to.path === "/") {
      // 切换路由从 /piano 到 / 时执行 handleScroll() 方法
      this.handleScroll();
    }
    next();
  },
};
</script>



<style lang="less" scoped>
.NavTop {
  .navBg {
    width: 100%;
    height: 102px;
    background: url(../../assets/img/navBg.png) no-repeat center;
    background-size: cover;
    position: fixed;
    z-index: 999;
    top: 140px;
    .nav {
      height: 80px;

      ul {
        width: 100%;
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;

        li,
        a {
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          cursor: pointer;
          padding-bottom: 3px;
          font-weight: 800;
        }

        li.active {
          border-bottom: 4px solid white;
        }
      }
    }
  }
}
</style>
