<template>
  <div class="copyright">
      <div class="footer clearfix" v-lazy-background="FooterBg">
                <div class="w foot">
                    <div class="txt1">
                        <div class="top">
                            <h3>课程对象</h3>
                        </div>
                        <div class="bottom">
                             <p>艺考生</p>
                            <p>演戏小白</p>
                            <p>兼职副业</p>
                        </div>
                    </div>
                    <div class="txt1">
                        <div class="top">
                            <h3>帮您提升</h3>
                        </div>
                        <div class="bottom noraml">
                            <p>声</p>
                            <p>台</p>
                            <p>形</p>
                            <p>表</p> 
                        </div>
                    </div>
                    <div class="txt1">
                        <div class="top">
                            <h3>官网导航</h3>
                        </div>
                        <div class="bottom">
                            <p>课程中心</p>
                            <p>新闻中心</p>
                            <p>走进艺途</p>
                            <p>联系我们</p> 
                        </div>
                    </div>
                    <div class="txt1">
                        <div class="top">
                            <h3>友情链接</h3>
                        </div>
                        <div class="bottom">
                             <p><a href="http://www.bjc-ent.com/">北京文化</a></p>
                            <p><a href="http://www.dfyanyi.com/index.html">中国东方演艺集团</a></p>
                            <p>上海戏剧学院继续教育学院</p> 
                        </div>
                    </div>
                    <div class="txt1">
                        <div class="top">
                            <h3>关注我们</h3>
                        </div>
                        <div class="bottom">
                            <img src="../../assets/img/wechat.png">
                        </div>
                    </div>
                    <div class="txt1">
                        <div class="top">
                            <img src="../../assets/img/footLogo.png">
                        </div>
                        <div class="bottom">
                            <img src="../../assets/img/footTel.png">&nbsp;
                            <span>艺途官方热线</span>
                            <h3>400-801-8866</h3>
                        </div>
                    </div>

                </div>
                <div class="copy">
                    <p> Copyright © 北京艺途国际艺术培训有限公司 版权所有 京ICP备2023008241号</p>
                </div>
            </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      FooterBg:require("../../assets/img/footer.png")
    }
  }
}
</script>

<style lang="less" scoped>

.footer {
  width: 100%;
  height: auto;

  .foot {
    margin: 70px auto;

    height: 300px;
    color: white;
    display: flex;

    .txt1 {
      width: auto;
      height: 130px;
      margin-right: 40px;

      .top {
        font-size: 20px;
        font-weight: 500;

        img {
          width: 66px;
          height: 58px;
        }
      }

      .bottom {
        span {
          position: relative;
          top: -4px;
        }

        margin-top: 20px;

        p,
        a {
          color: white;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
          line-height: 30px;
          transition: all 0.3s;
        }

        a:hover,
        p:hover {
          color: #2079ff;
        }

        h3 {
          cursor: pointer;
          font-size: 24px;
          font-weight: bold;
          line-height: 36px;
        }
      }
    }
  }

  .copy {
    position: relative;
    top: -150px;
    text-align: center;

    p {
      font-size: 12px;
      color: white;
    }
  }
}
</style>