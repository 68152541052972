<template>
  <div class="Title">
    <div class="info">
      <p class="w" v-if="false">
        欢迎访问艺途国际艺术培训公司，全国有影响力的影视培训中心！
      </p>
    </div>
    <div class="countion">
      <div class="box w">
        <div class="left-logo animate__animated animate__lightSpeedInLeft">
          <img src="../../assets/img/logo.png" />
          <div class="text">
            <h3>艺术之路，始于艺途</h3>
            <p>Yitu,YourRoad of Arts.</p>
          </div>
        </div>
        <div class="right-weachat animate__animated animate__lightSpeedInRight">
          <img src="../../assets/img/wechat.png" />
          <div class="text">
            <p class="font-sub" style="margin-top: 5px">全国统一服务热线</p>
            <div class="phone">
              <img src="../../assets/img/tel.png" /> &nbsp;
              <p class="font-sub">400-801-8866</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/css/public.css";

export default {
  data() {
    return {};
  },
};
</script>



<style lang="less" scoped>
.Title {
  .info {
    width: 100%;
    background: #ededed;
    font-size: 12px;
    font-weight: 400;
    color: #333333;
    line-height: 31px;

    z-index: 999;

    p {
      width: 1000px;
    }
  }

  .countion {
    width: 100%;
    height: 145px;

    top: 30px;
    background: white;

    .box {
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      .left-logo {
        display: flex;
        img {
        }
        .text {
          margin-left: 28px;

          font-weight: 500;
          color: #831925;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3 {
            font-size: 23px;
          }
          p {
            font-size: 18px;
          }
        }
      }

      .right-weachat {
        width: 300px;

        display: flex;

        .text {
          margin-left: 10px;

          .phone {
            margin-top: 15px;
            display: flex;
          }
        }
      }
    }
  }
}
</style>